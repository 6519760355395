<template>
  <div>
    <div class="flix-form-group" style="margin-top: 30px">
    <h3 class="flix-html-h3" style="float:left; margin:0; margin-right: 5px">{{ $t('message.add', {name: $t('message.dailyPauses')}) }}</h3>
      <a href="#" class="flix-btn flix-btn-xs flix-btn-info" @click.prevent="addPause"><flixIcon :id="'plus'" /></a>
    </div>
    <div class="flix-clearfix" />
    <transition name="flixFadeIn">
    <div :key="updateKey">
        <div class="flix-form-group" v-for="(time, index) in pause" :key="updateKey + index">
            <div :key="index ">
              <a href="#" class="flix-btn flix-btn-xs flix-btn-danger" style="float: left; margin-right: 10px " @click.prevent="removePause(index)"><flixIcon :id="'minus'" /></a>
              <timepicker v-if="pause[index]" :weekdays="pause" :index="index" :callback="function(data){changeHour(index, data)}" />
            </div>
            <a @click.prevent="setDay(index, weekdaynr)" href="#" class="flix-html-a flix-html-small" v-for="(weekday, weekdaynr) in data.include.weekdays" :key="weekdaynr" style="margin-right: 5px"><flixIcon :id="getIcon(index, weekdaynr)" /> {{ $t('constants.weekdays')[weekdaynr] }}</a>
        </div>
        <div v-if="Object.keys(pause).length">
          <a href="#" class="flix-html-a" @click.prevent="addPause"><flixIcon class="flix-btn flix-btn-xs flix-btn-info" :id="'plus'" /></a> <span>{{ $t('message.useDifferentPauses') }}</span>
        </div>
    </div>
  </transition>
  </div>
</template>
<script>
export default {
  components: {
    timepicker () { return import('@/components/assistent/components/assets/timepicker') }
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      count: 0,
      pause: {},
      days: {},
      updateKey: new Date().getTime()

    }
  },
  methods: {
    getPause () {
      var temp = {}
      if (Object.keys(this.data.exclude.weekdays).length === 0) {
        return false
      }

      Object.keys(this.data.exclude.weekdays).forEach(function (weekday) {
        Object.keys(this.data.exclude.weekdays[weekday]).forEach(function (i) {
          var id = new Date().getTime() + Math.random(0, 10)
          if (temp[JSON.stringify(this.data.exclude.weekdays[weekday][i])]) {
            id = temp[JSON.stringify(this.data.exclude.weekdays[weekday][i])]
          }
          this.pause[id] = this.data.exclude.weekdays[weekday][i]

          if (typeof this.days[id] === 'undefined') {
            this.days[id] = []
          }

          this.days[id].push(weekday)

          temp[JSON.stringify(this.data.exclude.weekdays[weekday][i])] = id
        }.bind(this))
      }.bind(this))
      this.updateKey = new Date().getTime()
    },
    setDay (index, weekdaynr) {
      if (Object.values(this.days[index]).indexOf(weekdaynr.toString()) !== -1) {
        this.days[index].forEach(function (v, k) {
          if (v * 1 === weekdaynr * 1) {
            this.days[index][k] = ''
            return false
          }
        }.bind(this))
      } else {
        this.days[index].push(weekdaynr.toString())
      }
      this.savePause()
      this.updateKey = new Date().getTime()
    },
    getIcon (index, weekdaynr) {
      if (Object.values(this.days[index]).indexOf(weekdaynr.toString()) !== -1) {
        return 'check'
      }
      return 'unchecked'
    },
    changeHour (index, time) {
      this.savePause()
    },
    savePause () {
      var exclude = {}
      Object.keys(this.pause).forEach(function (k) {
        Object.values(this.days[k]).forEach(function (day) {
          if (day === '') {
            return true
          }
          if (typeof exclude[day] === 'undefined') {
            exclude[day] = []
          }
          exclude[day].push(this.pause[k])
        }.bind(this))
      }.bind(this))

      this.callback({ exclude: { weekdays: exclude } })
    },
    addPause () {
      var id = new Date().getTime()
      this.pause[id] = ['12:00', '13:00']
      this.days[id] = []

      Object.keys(this.data.include.weekdays).forEach(function (k) {
        this.days[id].push(k)
      }.bind(this))

      this.savePause()
      this.updateKey = id
    },
    removePause (index) {
      delete this.pause[index]
      this.savePause()
      this.updateKey = new Date().getTime()
    }
  },
  mounted () {
    this.getPause()
  },
  computed: {

  }
}
</script>
  <style lang="sass" scoped>
    .arrow-right
      margin: 0 5px
      font-size: 16pt

  </style>
